import { Button, Modal, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TypeActivity, motiveReunion } from '../../utils/diccionario';
import { createNewActivity, editActivity, getAllActivities, updateActivity } from '../../features/data/dataSlice';
import './ModalEditDay.scss';

export default function ModalEditDay({ closeModal, visible, record }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [newActivity, setNewActivity] = useState({
    time: '',
    description: '',
    person: '',
    date: '',
    typeActivity: '',
    duration: '',
  });



  useEffect(() => {
    if (record) {
      setNewActivity({
        time: record.time,
        description: record.description,
        person: record.person,
        date: record.date,
        typeActivity: record.typeActivity,
        duration: record.duration,
      });
    }
  }, [record]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewActivity({
      ...newActivity,
      [name]: value,
    });
  };

  const handleEditActivity = () => {
    if (record) {
      dispatch(updateActivity({ _id: record._id, data: newActivity }));
      message.success('Actividad editada con éxito');
    } else {
      dispatch(createNewActivity(newActivity));
      message.success('Actividad agregada con éxito');
    }

    closeModal();
  };

  const handleCancel = () => {
    closeModal();
  };

  return (
    <Modal
      title={record ? 'Editar actividad' : 'Registrar actividad'}
      footer={null}
      visible={visible}
      onCancel={handleCancel}
      wrapClassName={visible ? 'animated-modal-up' : 'animated-modal-down'}
      className="border-radius-modal"
    >
      <div className='form-edit-day'>
        <form>
          <h3>Observaciones:</h3>
          <textarea
            type='text'
            name='description'
            value={newActivity.description}
            onChange={handleInputChange}
          />
          <h3>Con quien?</h3>
          <input
            type='text'
            name='person'
            value={newActivity.person}
            onChange={handleInputChange}
          />
          <h3>Hora, fecha y duración</h3>
          <input
            style={{ width: '20%' }}
            type='time'
            name='time'
            value={newActivity.time}
            onChange={handleInputChange}
          />
          <input
            style={{ width: '30%', marginLeft: '10px' }}
            type='date'
            name='date'
            value={newActivity.date}
            onChange={handleInputChange}
          />
          <input
            style={{ width: '20%', marginLeft: '10px' }}
            type="number"
            name='duration'
            value={newActivity.duration}
            placeholder='Minutos'
            onChange={handleInputChange}
          />

          <h3>Tipo de actividad</h3>
          <select
            name='typeActivity'
            value={newActivity.typeActivity}
            onChange={handleInputChange}
          >
            <option value='' disabled>Seleccione actividad</option>
            {TypeActivity.map((opcion) => (
              <option key={opcion.key} value={opcion.value}>
                {opcion.label + '' + opcion.description}
              </option>
            ))}
          </select>

          <div className='btns-edit'>
            <Button type='primary' className='btn-edit-day' onClick={handleEditActivity}>
              {record ? 'Editar Actividad' : 'Registrar Actividad'}
            </Button>
            <Button type='primary' className='btn-folow-action' onClick={() => navigate(`/dashboard`)}>
              Agregar Acción de seguimiento
            </Button>
          </div>

        </form>
      </div>
    </Modal>
  );
}
