import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllClientsByAdmin, reset } from "../../features/client/clientSlice";
import Client from "./Client/Client";

const Clients = () => {
  const dispatch = useDispatch();
  

  useEffect(() => {
    dispatch(getAllClientsByAdmin());
    dispatch(reset())
  }, []);
  
  return (
    <div>
      < Client/>
    </div>
  );
};

export default Clients;