import React, { useEffect } from "react";
import { BsArrowLeftShort, BsFillPhoneFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getClientById } from "../../features/client/clientSlice";
import { IoIosSchool } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { AiFillHome, AiOutlineUser } from "react-icons/ai";
import { ImUsers } from "react-icons/im";
import { GiLinkedRings } from "react-icons/gi";

import "./ClientProfile.scss";
import { generadorCuando } from "../../utils/funtions";
import { WhatsAppOutlined } from "@ant-design/icons";

const ClientProfile = () => {
  const navigate = useNavigate();
  const { client } = useSelector((state) => state.clients);
  const { _id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClientById(_id));
  }, [_id]);

  const handlePhoneClick = () => {
    if (client?.phonenumber) {
      window.location.href = `tel:${client.phonenumber}`;
    }
  };

  const renderActionDetails = (key, items) => {
    if (!Array.isArray(items) || items.length === 0) {
      return <p>No hay detalles disponibles para {key}.</p>;
    }

    switch (key) {
      case 'call':
        return items.map((item) => (
          <div key={item._id}>
            <p>Motivo de la llamada: {item.observations} <a href={`tel:${client?.phonenumber}`} onClick={handlePhoneClick}>
              <BsFillPhoneFill
                style={{
                  color: '#1890FF',
                  fontSize: '20px',
                  cursor: 'pointer'
                }}
              />
            </a></p>
            <p>Fecha de contacto: {generadorCuando(item.contactDate * 1000, "DD/MM/YYYY").str} </p>
          </div>
        ));
      case 'rememberDate':
        return items.map((item) => (
          <div key={item._id}>
            <p>Recordar: {item.remember} <a href={`https://wa.me/${client?.phonenumber}`} target="_blank" rel="noopener noreferrer">
                <WhatsAppOutlined
                  style={{
                    color: '#1890FF',
                    fontSize: '20px',
                    cursor: 'pointer',
                    marginRight: '10px',
                  }}
                />
              </a></p>
            <p>
              Fecha a recordar: {generadorCuando(item.dateToRemember * 1000, "DD/MM").str}</p>
          </div>
        ));
      case 'regards':
        return items.map((item) => (
          <div key={item._id}>
            <p>Tipo de saludo: {item.greetingsType} <a href={`https://wa.me/${client?.phonenumber}`} target="_blank" rel="noopener noreferrer">
                <WhatsAppOutlined
                  style={{
                    color: '#1890FF',
                    fontSize: '20px',
                    cursor: 'pointer',
                    marginRight: '10px',
                  }}
                />
              </a></p>
            <p>Fecha del saludo: {generadorCuando(item.dateOfGreetings * 1000, "DD/MM/YYYY").str}</p>
          </div>
        ));
      case 'popBy':
        return items.map((item) => (
          <div key={item._id}>
            <p>Descripción: {item.descripcion}</p>
            <p>Fecha de Pop By: {generadorCuando(item.datepopBy * 1000, "DD/MM/YYYY").str}</p>
            <p>Valor: {item.value}</p>
          </div>
        ));
      default:
        return null;
    }
  };

  const groupedActions = client?.followUpActions || [];



  return (
    <div key={client?._id} className="container-client">
      <div className="header-profile-name">
        <div className="nav-header">
          <BsArrowLeftShort onClick={() => navigate(-1)} />
          <AiOutlineUser onClick={() => navigate(`/clientprofile/${client._id}`)} />
        </div>
        <div>Cliente - {client?.name} {client?.qualification?.[0]?.qualification || 'Sin calificación'}</div>
      </div>
      <div className="information">
        <span>Información Personal</span>
        <div className="btn-icon-client">
          <IoIosSchool /> Profesión - {client?.personalData?.titleprofession}
        </div>
        <div className="btn-icon-client telef" style={{ cursor: 'pointer' }}>
          <a href={`tel:${client?.phonenumber}`} onClick={handlePhoneClick}>
            <BsFillPhoneFill /> Móvil: {client?.phonenumber}
          </a>
        </div>

        <div className="btn-icon-client">
          <MdEmail /> Correo electrónico: {client?.email}
        </div>
        <div className="btn-icon-client">
          <AiFillHome /> Dirección: {client?.address}
        </div>
      </div>
      <div className="information">
        <span>Familia</span>
        {client?.familyData?.couple && (
          <div className="btn-icon-client">
            <GiLinkedRings /> Pareja: {client?.familyData?.couple}
          </div>
        )}
        {client?.familyData?.children.length > 0 && (
          <div className="btn-icon-client">
            <ImUsers /> {`Hij${client?.familyData?.children.length === 1 ? '@' : 'os'}`} -{' '}
            {client?.familyData?.children.map((child, index) => {
              if (index === client.familyData.children.length - 1) {
                return child;
              } else {
                return child + ', ';
              }
            })}
          </div>
        )}
      </div>
      <div className="information">
        <span>Acciones de seguimiento</span>
        {groupedActions.map((action, index) => (
          <div key={index}>
            {Object.keys(action).map((key) => (
              <div key={key}>
                <h5>
                  {key === 'call' && 'Llamadas'}
                  {key === 'rememberDate' && 'Fechas para recordar'}
                  {key === 'regards' && 'Saludos'}
                  {key === 'popBy' && 'Pop By - Presentes'}
                </h5>
                {renderActionDetails(key, action[key])}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClientProfile;
