import axios from "axios";


const API_URL = process.env.REACT_APP_BACK_URL;


const user = JSON.parse(localStorage.getItem("user"));

const createClientByAdmin = async (clientData) => {
  const res = await axios.post(
    API_URL + "/clients/createClientByAdmin",
    clientData,
    {
      headers: {
        authorization: user?.token,
      },
    }
  );
  return res.data;
};
const getAllClientsByAdmin = async () => {
  const res = await axios.get(API_URL + "/clients/getAllClientsByAdmin", {
    headers: {
      authorization: user?.token,
    },
  });
  return res.data;
};
const getClientById = async (_id) => {
  const res = await axios.get(API_URL + "/clients/getClientById/" + _id);
  return res.data;
};

const updateFamilyData = async (_id, familyData) => {
  const res = await axios.put(
    API_URL + `/clients/updateFamilyData/${_id}`,
    familyData,
    {
      headers: {
        authorization: user?.token,
      },
    }
  );
  return res.data;
};

const createPropertyForClient = async (propertyData) => {
  const res = await axios.post(
    API_URL + "/property/createProperty",
    propertyData,
    {
      headers: {
        authorization: user?.token,
      },
    }
  );
  return res.data;
}

const clientService = {
  createClientByAdmin,
  getAllClientsByAdmin,
  getClientById,
  updateFamilyData, 
  createPropertyForClient,
};

export default clientService;