import axios from "axios";

const API_URL = process.env.REACT_APP_BACK_URL;

const user = JSON.parse(localStorage.getItem("user"));


const register = async (userData) => {
  const res = await axios.post(API_URL + "/admin/register", userData);
  return res.data;
};

const login = async (userData) => {
  
  const res = await axios.post(API_URL + '/admin/login', userData);
  if (res.data) {
    localStorage.setItem("user", JSON.stringify(res.data));
  }
  return res.data;
};

const logout = async (token) => {
  const config = {
    headers: {
      authorization: user?.token,
    },
  };

  const res = await axios.delete(API_URL + "/admin/logout", config);
  localStorage.removeItem("user");
  return res.data;
};

const authService = {
  register,
  login,
  logout,
};

export default authService;
