import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import "./Client.scss";
import { BsArrowLeftShort } from "react-icons/bs";
import { Spin } from "antd";

const Client = () => {
  const navigate = useNavigate();
  const { clients } = useSelector((state) => state.clients);
  
  if (!clients?.clients || clients.clients.length === 0) {
    return (
      <div>
        <p>No hay clientes disponibles.</p>
      </div>
    );
  }

  if (!clients?.clients || clients.clients.length === 0) {
    return (
      <div>
        <p>No hay clientes disponibles.</p>
      </div>
    );
  }

  if (!clients?.clients || !clients?.clients.length) {
    return (
      <div>
        <p className="text-search">Buscando sus clientes. Aguarde por favor</p>
        <Spin tip="Buscando" size="large" style={{ margin: 50 }} />
      </div>
    );
  }

  // Ordenar clientes alfabéticamente por apellido y mostrar activos primero
  const sortedClients = clients.clients
    .slice() // Hacer una copia para no modificar el array original
    .sort((a, b) => {
      // Primero ordenar por estado (activos primero)
      if (a.status === true && b.status === false) {
        return -1; // a viene antes que b
      } else if (a.status === false && b.status === true) {
        return 1; // b viene antes que a
      }

      // Si ambos tienen el mismo estado, ordenar por apellido
      return a.lastname.localeCompare(b.lastname);
    });

  return (
    <div className="container-clients">
      <div className="header-profile-name">
        <div className="nav-header">
          <BsArrowLeftShort onClick={() => navigate(-1)} />
        </div>
        <div>Estos son tus clientes</div>
      </div>
      {sortedClients.map((client, id) => (
        <div key={id} className={`container-client-many ${client.status === true ? "active" : "inactive"}`}>
          <div onClick={() => navigate(`/clients/${client._id}`)} className="client-many">
            {client.status === true ? (
              <FaUserCircle className="icon-client-many-active" />
            ) : (
               <FaUserCircle className="icon-client-many-inactive" />
            )}
            <p>{client.name}, {client.lastname}</p>
            <br />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Client;
