import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftShort } from 'react-icons/bs';
import { Modal, Button, Checkbox } from 'antd'; // Ajusta según tus librerías y estilos
import './CustomerAcquisition.scss';



  const CustomerAcquisition = ({ modalVisibleCA, onClose }) => {
    const tasks = [
      "Agendar contacto en base de datos",
      "Ficha de prelisting según corresponda (PH o Casa)",
      "Prelistin/escucha activa/llevar agenda/agendar presentación de ACM",
      "Presentación de ACM/gastos/datos legales",
      "Firma autorización/documentación y armado de reserva y guía boleto",
      "Paga o no paga ITI? Tiene clave fiscal?",
      "Tenes escritura original? SI / NO",
      "Hay sucesión? SI / NO",
      "Hay declaratoria de herederos?",
      "Esta incorporado el bien?",
      "Esta hecho el estado parcelario?",
      "Se pidieron informes de dominio e inhibición de los causantes y cesion de los causantes?",
      "N° de Juzgado? Ubicacion?",
      "N° de Expediente",
      "Carátula",
      "Celular del Abogado",
      "CASA: Autorización - DNI - Escritura - Plano de obra - AYSA - ARBA - ARQUI - EDESUR - METROGAS",
      "PH: Autorización - DNI - Escritura - Reglamento de copropiedad - Plano de obra - Plano de PH - AYSA - ARBA - ARQUI - EDESUR - METROGAS - Libre de deuda expensas",
      "SUCESIÓN - Declaratoria de herederos - Teléfono del abogado - Carátula-Juzgado y número de expediente - Estado parcelario",
      "ESTADO DE LOS SERVICIOS - Conexión de agua luz y gas - Alguna reciclada? - Planos actualizados?",
      "COTI",
      "Armado de reserva",
    ];
    
  
    return (
      <Modal
        title="Checklist de Captación"
        open={modalVisibleCA}
        onCancel={onClose}
        footer={[
          <Button key="back" onClick={onClose}>
            Cerrar
          </Button>,
        ]}
      >
        
          {tasks.map((task, index) => (
            <p key={index}>
              <Checkbox>{task}</Checkbox>
            </p>
          ))}
      </Modal>
    );
  };
  
  export default CustomerAcquisition;