import { notification } from "antd";
import React, { useEffect, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createQualification, reset, updateQualification } from "../../features/data/dataSlice";
import "./Qualification.scss";

const Qualification = () => {
  const { client } = useSelector((state) => state.clients);
  const { _id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isError, isSuccess, message } = useSelector((state) => state.data);
  const [clientQualiData, setClientQualiData] = useState({
    clientId: _id,
    qualification: "",
  });

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: "Felicitaciones",
        description: "Información agregada con éxito",
      });
    }
    if (isError) {
      notification.error({ message: "Error", description: message });
    }
    dispatch(reset());
  }, [isSuccess, isError, message]);

  const onSubmit = (newQualification) => {
    const existingQualification = client?.qualification[0]?.qualification;

    if (existingQualification) {
      dispatch(updateQualification({
        clientId: _id,
        qualification: newQualification,
        qualificationId: client.qualification[0]._id,
      }));
    } else {
      dispatch(createQualification({
        clientId: _id,
        qualification: newQualification,
      }));
    }

    setTimeout(() => {
      navigate(-1);
    }, 2000);
  };

  return (
    <div>
      <div className="header-profile-name">
        <div className="nav-header">
          <BsArrowLeftShort onClick={() => navigate(-1)} />
        </div>
        <div>Clasifica a tu cliente</div>
      </div>
      <div className="container-square">
        <div className="square aa" value="A+" onClick={() => onSubmit("A+")}>
          A+
        </div>
        <div className="square a" value="A" onClick={() => onSubmit("A")}>
          A
        </div>
        <div className="square b" value="B" onClick={() => onSubmit("B")}>
          B
        </div>
        <div className="square c" value="" onClick={() => onSubmit("C")}>
          C
        </div>
      </div>
    </div>
  );
};

export default Qualification;