import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AddIncident from "./components/AddClient/Addclient";
import AddFamilyData from "./components/AddFamilyData/AddFamilyData";
import AddPersonalData from "./components/AddPersonalData/AddPersonalData";
import ClientDetail from "./components/ClientDetail/ClientDetail";
import ClientProfile from "./components/ClientProfile/ClientProfile";
import Client from "./components/Clients/Client/Client";
import Clients from "./components/Clients/Clients";
import ClientsFollow from "./components/ClientsFollow/ClientsFollow";
import Dashboard from "./components/Dashboard/Dashboard";
import FollowUpActions from "./components/FollowUpActions/FollowUpActions";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
import Qualification from "./components/Qualification/Qualification";
import Register from "./components/Register/Register";
import Wellcome from "./components/Wellcome/Wellcome";
import PrivateZone from "./guards/PrivateZone";
import ClientInactive from "./components/ClientInactive/ClientInactive";
import Process from "./components/Process/Process";
import CustomerAcquisition from "./components/Process/CustomerAcquisition/CustomerAcquisition";
import Publication from "./components/Process/Publication/Publication";
import Signaling from "./components/Process/Signaling/Signaling";
import AddProperty from "./components/AddProperty/AddProperty";
import Profile from "./components/Profile/Profile";
import OrganizeMyDay from "./components/OrganizeMyDay/OrganizeMyDay";

function App() {
  const { user } = useSelector((state) => state.auth);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <PrivateZone> <Home /> </PrivateZone> } />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/wellcome" element={ <PrivateZone>  <Wellcome /></PrivateZone>}  />
          <Route path="/addclient" element={<PrivateZone>  <AddIncident /> </PrivateZone> } />
          <Route path="/clients" element={ <PrivateZone> <Clients /></PrivateZone>} />
          <Route path="/clients/:_id" element={ <PrivateZone> <ClientDetail /></PrivateZone>} />
          <Route path="/client" element={ <PrivateZone> <Client /></PrivateZone>} />
          <Route path="/dashboard" element={ <PrivateZone> <Dashboard /></PrivateZone>} />
          <Route path="/clientprofile/:_id" element={ <PrivateZone> <ClientProfile /></PrivateZone>} />
          <Route path="/addpersonaldata/:_id" element={ <PrivateZone> <AddPersonalData /></PrivateZone>} />
          <Route path="/followupactions/:_id" element={ <PrivateZone> <FollowUpActions /></PrivateZone>} />
          <Route path="/addfamilydata/:_id" element={ <PrivateZone> <AddFamilyData /></PrivateZone>} />
          <Route path="/qualification/:_id" element={ <PrivateZone> <Qualification /></PrivateZone>} />
          <Route path="/clientsfollow" element={ <PrivateZone> <ClientsFollow /> </PrivateZone>} />
          <Route path="/clientsinactive" element={ <PrivateZone> <ClientInactive /> </PrivateZone>} />
          <Route path="/salesprocess" element={ <PrivateZone> <Process /> </PrivateZone>} />
          <Route path="/customeracquisition" element={ <PrivateZone> <CustomerAcquisition /> </PrivateZone>} />
          <Route path="/publication" element={ <PrivateZone> <Publication /> </PrivateZone>} />
          <Route path="/signaling" element={ <PrivateZone> <Signaling /> </PrivateZone>} />
          <Route path="/addproperty/:_id" element={ <PrivateZone> <AddProperty /> </PrivateZone>} />
          <Route path="/profile" element={ <PrivateZone> <Profile /> </PrivateZone>} />
          <Route path="/organizemyday" element={ <PrivateZone> <OrganizeMyDay /> </PrivateZone>} />
          
          
          
          
          
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
