import { Button, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/img/logo-con-letras.png";
import { register } from "../../features/auth/authSlice";
import { reset } from "../../features/data/dataSlice";
import "./Register.scss";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isError, isSuccess, message } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    username: "",
    name: "",
    email: "",
    password: "",
    password2: "",
  });
  const { username, name, email, password, password2 } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: "Usuario creado con éxito",
        description: "Se le ha enviado un correo de confirmación",
      });
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    }
    if (isError) {
      notification.error({ message: "Error", description: message });
    }
    dispatch(reset());
  }, [isSuccess, isError, message, navigate, dispatch]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (password !== password2) {
      return notification.error({
        message: "Error",
        description: "Las contraseñas no coinciden",
      });
    } else {
      dispatch(register(formData));
      setFormData({
        username: "",
        name: "",
        email: "",
        password: "",
        password2: "",
      });
    }
  };

  return (
    <div className="register">
      <img src={Logo} alt="Logo-G-free" className="logo" />
      <form className="register-form" onSubmit={onSubmit}>
        <input
          type="text"
          name="username"
          value={username}
          placeholder="USERNAME"
          onChange={onChange}
          required
        />
        <input
          type="text"
          name="name"
          value={name}
          placeholder="NOMBRE Y APELLIDO"
          onChange={onChange}
          required
        />
        <input
          type="email"
          name="email"
          value={email}
          placeholder="E-MAIL"
          onChange={onChange}
          required
        />

        <input
          type="password"
          name="password"
          value={password}
          placeholder="CONSTRASEÑA"
          onChange={onChange}
          required
        />
        <input
          type="password"
          name="password2"
          value={password2}
          placeholder="CONFIRMAR CONTRASEÑA"
          onChange={onChange}
          required
        />

        <Button
          type="primary"
          block
          htmlType="submit"
          className="register-form-button"
        >
          Registrarse
        </Button>
      </form>

      <p>¿Ya tienes cuenta?</p>
       <Link to="/login">
        <span>Ingresa!</span>
      </Link>
    </div>
  );
};
export default Register;
