import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getClientById } from "../../features/client/clientSlice";
import { TfiWrite } from "react-icons/tfi";
import { VscGithubAction } from "react-icons/vsc";
import { MdFamilyRestroom } from "react-icons/md";
import { AiOutlineTags, AiOutlineUser } from "react-icons/ai";
import { BsArrowLeftShort } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import "./ClientDetail.scss";
import { UpdateStatus } from "../../features/data/dataSlice";
import { Button, Popconfirm, Tooltip } from "antd";
import { HomeOutlined, QuestionCircleOutlined } from "@ant-design/icons";

const ClientDetail = () => {
  const navigate = useNavigate();
  const { _id } = useParams();
  const dispatch = useDispatch();
  const { client } = useSelector((state) => state.clients);

  useEffect(() => {
    dispatch(getClientById(_id));
  }, []);

  const onSubmit = (_id, status = false) => {
    dispatch(UpdateStatus({
      client: _id,
      status: status
    }));
    navigate(-1);
  };

  return (
    <div className="todo">
      <div className="header-profile-name">
        <div className="nav-header">
          <BsArrowLeftShort onClick={() => navigate("/clients/")} />{" "}
          <AiOutlineUser
            onClick={() => navigate(`/clientprofile/${client._id}`)}
          />
        </div>
        <div>Cliente - {client?.name} {client?.qualification?.[0]?.qualification || 'Sin calificación'}</div>


      </div>

      <div key={client?._id} className="container-client">
        <Button
          className="button-profile"
          type="primary"
          onClick={() => navigate(`/clientprofile/${client._id}`)}
        >Ver perfil de {client?.name}
        </Button>
        <div className="container-actions-sup">
          <div
            className="container-text-icon"
            onClick={() => navigate(`/addpersonaldata/${client._id}`)}
          >
            <div className="a">
              <TfiWrite />
            </div>
            <p>Información personal</p>
          </div>
          <div
            className="container-text-icon"
            onClick={() => navigate(`/followupactions/${client._id}`)}
          >
            <div className="b">
              <VscGithubAction />
            </div>
            <p>Acciones de seguimiento</p>
          </div>
          <div
            className="container-text-icon"
            onClick={() => navigate(`/addfamilydata/${client._id}`)}
          >
            <div className="c">
              <MdFamilyRestroom />
            </div>
            <p>Datos familiares</p>
          </div>
          <div
            className="container-text-icon"
            onClick={() => navigate(`/qualification/${client._id}`)}
          >
            <div className="d">
              <AiOutlineTags />
            </div>
            <p>calificacion de clientes</p>
          </div>
          <div
            className="container-text-icon"
            onClick={() => navigate(`/addproperty/${client._id}`)}
          >
            <div className="e">
              <HomeOutlined />
            </div>
            <p>Agregar propiedad para vender/alquilar</p>
          </div>
          <div>
          </div>
          {client?.status === true ? (
            <Popconfirm title="Va cambiar el estado del ciente a incativo?"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              okText="Si"
              cancelText="No"
              className="popconfirm"
              placement="bottom"
              onConfirm={() => onSubmit(`${client._id}`, false)}
            >
              <Button
                icon={<TiDeleteOutline className="icon-btn" style={{ fontSize: "20" }} />}
                type="primary"
                title=""
                className="text-delete-state update-state  "
                style={{ height: "50px", borderRadius: "5px" }}
              >
                Cliente inactivo
              </Button>
            </Popconfirm>
          ) : (

            <Popconfirm title="Vas activar este cliente?"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              okText="Si"
              cancelText="No"
              className="popconfirm"
              placement="bottom"
              onConfirm={() => onSubmit(`${client._id}`, true)}
            >
              <Button
                icon={<TiDeleteOutline className="icon-btn" style={{ fontSize: "20" }} />}
                type="primary"
                title=""
                className="text-delete-state update-state"
                style={{ height: "50px", borderRadius: "5px" }}
              >
                Activar cliente
              </Button>
            </Popconfirm>
          )}



        </div>
      </div>
    </div>
  );
};

export default ClientDetail;
