import React, { useEffect, useState } from "react";
import { Button, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./AddClient.scss";
import { createClientByAdmin } from "../../features/client/clientSlice";
import { reset } from "../../features/auth/authSlice";
import { BsArrowLeftShort } from "react-icons/bs";
import { fuentes } from "../../utils/diccionario";

const AddIncident = () => {
  const { isError, isSuccess, message } = useSelector((state) => state.clients);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clientData, setClientData] = useState({
    name: "",
    lastname: "",
    email: "",
    phonenumber: "",
    address: "",
    age: "",
    birthday: "",
    source: "",
  });
  const { name, lastname, email, phonenumber, address, age, birthday, source } =
    clientData;

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: "Felicitaciones",
        description: "Cliente creado con éxito",
      });
    }
    if (isError) {
      notification.error({ message: "Error", description: message });
    }
    dispatch(reset());
    // eslint-disable-next-line
  }, [isSuccess, isError, message]);

  const onChange = (e) => {
    setClientData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(createClientByAdmin(clientData));
    setClientData({
      name: "",
      lastname: "",
      email: "",
      phonenumber: "",
      address: "",
      age: "",
      birthday: "",
      source: "",
    });
    setTimeout(() => {
      navigate("/dashboard");
    }, 2000);
  };

  return (
    <div className="addClient">
      <div className="header-profile-name">
        <div className="nav-header">
          <BsArrowLeftShort onClick={() => navigate(-1)} />

        </div>
        <p className="text-header"> Rellena los datos del nuevo cliente</p>
      </div>
      <div>
        <form className="addClient-form" onSubmit={onSubmit}>
          <input
            type="text"
            name="name"
            value={name}
            placeholder="NOMBRE"
            onChange={onChange}
            required
          />
          <input
            type="text"
            name="lastname"
            value={lastname}
            placeholder="APELLIDO"
            onChange={onChange}
            required
          />
          <input
            type="email"
            name="email"
            value={email}
            placeholder="E-MAIL"
            onChange={onChange}
          />

          <input
            type="tel"
            name="phonenumber"
            value={phonenumber}
            placeholder="TELEFONO"
            onChange={onChange}
            required
          />
          <input
            type="text"
            name="address"
            value={address}
            placeholder="DIRECCION"
            onChange={onChange}
          />

          <input
            type="number"
            name="age"
            value={age}
            placeholder="EDAD"
            onChange={onChange}
          />
          <input
            type="date"
            name="birthday"
            value={birthday}
            placeholder="FECHA DE NACIMIENTO"
            onChange={onChange}
          />
          <select
            type="select"
            name="source"
            value={source}
            placeholder="FUENTE"
            onChange={onChange}
          >
            {fuentes.map((opcion) => (
              <option key={opcion.key} value={opcion.label}>
                {opcion.label}
              </option>
            ))}
          </select>
          <Button type="primary" block htmlType="submit" className="ant-btn">
            Crear Cliente
          </Button>
        </form>
      </div>
    </div>
  );
};

export default AddIncident;
