import React, { useState } from 'react';
import { Button, Card, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import './Process.scss';
import { BsArrowLeftShort } from 'react-icons/bs';
import { AiOutlineUser } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import CustomerAcquisition from './CustomerAcquisition/CustomerAcquisition';
import Publication from './Publication/Publication';
import Signaling from './Signaling/Signaling';

const Process = () => {
  const navigate = useNavigate();
  const { clients } = useSelector((state) => state.clients);
  const [modalVisibleCA, setModalVisibleCA] = useState(false);
  const [modalVisibleP, setModalVisibleP] = useState(false);
  const [modalVisibleS, setModalVisibleS] = useState(false);


  if (!clients?.clients || clients.clients.length === 0) {
    return (
      <div>
        <p>No hay propiedades.</p>
      </div>
    );
  }

  if (!clients?.clients || !clients?.clients.length) {
    return (
      <div>
        <p className="text-search">Buscando sus Propiedades. Aguarde por favor</p>
        <Spin tip="Buscando" size="large" style={{ margin: 50 }} />
      </div>
    );
  }

  const processClientes = clients.clients.filter(
    (client) => client?.property === true && client?.propertyData?.length > 0
  );

  return (
    <div className="container-process">
      <div className="header-profile-name">
        <div className="nav-header">
          <BsArrowLeftShort onClick={() => navigate(-1)} />
        </div>
        <div>Propiedades que gestionas</div>
      </div>
      <div>
        {processClientes.map((client) => (
          <div key={client._id}>
            {client.propertyData &&
              client.propertyData.map((property, index) => (
                <Card key={index} style={{ margin: '10px' }} className='card-property'>
                  <div >
                    <div className='info-container'>
                      <p>Cliente: {client.name} {client.lastname} </p>
                      <p>Propiedad: {property?.name}</p>
                      <p>tipo de operacion: {property?.typeOperation}</p>
                      <p>Estado: {property?.statusProcess}</p>
                    </div>
                    <div className='image-container'>
                      <img src="https://i.blogs.es/c68014/casa-3d/840_560.jpeg" alt="" srcset="" />
                    </div>
                  </div>

                  <div className='container-btn'>
                    <Button
                      className="button-process"
                      type="primary"
                      onClick={() => setModalVisibleCA(true)} 
                    >
                      Captaciones
                    </Button>
                    <Button
                      className="button-process"
                      type="primary"
                      onClick={() => setModalVisibleP(true)} 
                    >
                      Publicacion
                    </Button>
                    <Button
                      className="button-process"
                      type="primary"
                      onClick={() => setModalVisibleS(true)} 
                    >
                      Firma
                    </Button>

                  </div>

                </Card>
              ))}
          </div>
        ))}
      </div>
      <CustomerAcquisition modalVisibleCA={modalVisibleCA} onClose={() => setModalVisibleCA(false)} />
      <Publication modalVisibleP={modalVisibleP} onClose={() => setModalVisibleP(false)} />
      <Signaling modalVisibleS={modalVisibleS} onClose={() => setModalVisibleS(false)} />

    </div>
  );
};

export default Process;
