import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { BsArrowLeftShort } from "react-icons/bs";
import { Spin, Button } from "antd";

const ClientFollow = () => {
  const navigate = useNavigate();
  const { clients } = useSelector((state) => state.clients);

  if (!clients?.clients || clients.clients.length === 0) {
    return (
      <div>
        <p>No hay clientes disponibles.</p>
      </div>
    );
  }

  if (!clients?.clients || !clients?.clients.length) {
    return (
      <div>
        <p className="text-search">Buscando sus clientes. Aguarde por favor</p>
        <Spin tip="Buscando" size="large" style={{ margin: 50 }} />
      </div>
    );
  }

  const clientsWithFollowUpActions = clients.clients.filter(
    (client) => client.followUpActions?.length > 0
  );

  // Ordenar clientes con seguimiento alfabéticamente por apellido
  const sortedClients = clientsWithFollowUpActions
    .slice() // Hacer una copia para no modificar el array original
    .sort((a, b) => a.lastname.localeCompare(b.lastname));

  if (sortedClients.length === 0) {
    return (
      <div>
        <div className="header-profile-name">
          <div className="nav-header">
            <BsArrowLeftShort onClick={() => navigate(-1)} />
          </div>
          <div className="text-header">Estos son tus clientes con seguimiento</div>
        </div>
        <p>No tienes clientes con seguimiento en este momento.</p>
        <Button onClick={() => navigate("/dashboard")}>Volver al dashboard </Button>
      </div>
    );
  }

  return (
    <div>
      <div className="header-profile-name">
        <div className="nav-header">
          <BsArrowLeftShort onClick={() => navigate(-1)} />
        </div>
        <div className="text-header">Estos son tus clientes con seguimiento</div>
      </div>
      {sortedClients.map((client, id) => (
        <div>
          <div key={id} className="container-client-many">
            <div onClick={() => navigate(`/clients/${client._id}`)} className="client-many">
              {client?.status === true ? (
                <FaUserCircle className="icon-client-many-active" />
              ) : (
                <FaUserCircle className="icon-client-many-inactive" />
              )}
              <p>
                {client.name}, {client.lastname}
              </p>
              <br />
            </div>
          </div>
          <p>
            Acciones de seguimiento: {client.followUpActions.length}            
          </p>

        </div>

      ))}
    </div>
  );
};

export default ClientFollow;
