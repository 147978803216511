// Profile.js

import { Button, Card, message } from 'antd';
import Meta from 'antd/es/card/Meta';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../features/auth/authSlice';
import './Profile.scss'; // Asegúrate de tener la ruta correcta al archivo SCSS

export default function Profile() {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  return (
    <div className="profile-container">
      <Card
        hoverable
        style={{
          width: '100%', // Se ajusta para ocupar todo el ancho del contenedor
        }}
        cover={<img alt="example" src="https://alt72.com.ar/wp-content/uploads/2014/09/login.png" />}
      >
        <Meta title={user?.admin.name} description={user?.admin.email} />
      </Card>
      <Button
       className='btn-logout'
        type="primary"
        danger
        onClick={() => {
          dispatch(logout());
          message.success('Has cerrado sesión');
        }}
      >
        Cerrar sesión
      </Button>
    </div>
  );
}
