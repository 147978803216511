import React, { useEffect, useState } from 'react';
import './OrganizeMyDay.scss';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Table, message } from 'antd';
import { TypeActivity, motiveReunion } from '../../utils/diccionario';
import { createNewActivity, getAllActivities } from '../../features/data/dataSlice';
import { generadorCuando, groupAndSummarizeActivities } from '../../utils/funtions';
import { FaCircle } from "react-icons/fa";
import ModalEditDay from './ModalEditDay';


export default function OrganizeMyDay() {
  const { client } = useSelector((state) => state.clients);
  const { activities } = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [newActivity, setNewActivity] = useState({
    time: '',
    description: '',
    person: '',
    date: '',
    typeActivity: '',
    duration: '',
  });

  // Nuevo estado para la fecha seleccionada
  const [selectedDate, setSelectedDate] = useState(new Date());

  // Estado para el modal de edición
  const [isModalEditDayVisible, setIsModalEditDayVisible] = useState(false);

  // Estado para almacenar el registro seleccionado
  const [selectedRecord, setSelectedRecord] = useState(null);

  useEffect(() => {
    dispatch(getAllActivities());
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewActivity({
      ...newActivity,
      [name]: value,
    });
  };

  const handleAddActivity = () => {
    dispatch(createNewActivity(newActivity));
    message.success('Actividad agregada con éxito');
    dispatch(getAllActivities());
    setNewActivity({
      time: '',
      description: '',
      person: '',
      date: '',
      typeActivity: '',
      duration: '',
    });
  };

  const filteredActivities = activities?.activities?.filter(activity => {
    const activityDate = new Date(activity.date);
    return activityDate.toDateString() === selectedDate.toDateString();
  }) || [];

  const columns = [
    {
      title: 'Día',
      dataIndex: 'date',
      key: 'date',
      render: (x) => x ? generadorCuando(x, 'DD/MM').str : 'No Aplica',
      width: 50,
      align: 'center'
    },
    {
      title: 'Hora',
      dataIndex: 'time',
      key: 'time',
      width: 100,
      align: 'center'
    },
    {
      title: 'Actividad',
      dataIndex: 'description',
      key: 'description',
      width: 150,
      align: 'center',
      render: (text) => <span style={{ fontSize: '13px' }}>{text}</span>,
    },
    {
      title: 'Persona',
      dataIndex: 'person',
      key: 'person',
      width: 50,
      align: 'center',
      render: (text) => <span style={{ fontSize: '13px' }}>{text}</span>,
    },
    {
      title: 'Editar / Registar',
      dataIndex: 'actions',
      key: 'actions',
      width: 50,
      align: 'center',
      render: (x, record) => (
        <FaCircle
          style={{
            fontSize: '20px',
            border: '1px solid gray',
            borderRadius: '50%',
            color: record.typeActivity === 'Verde' ? '#00FF00' :
              record.typeActivity === 'Amarillo' ? '#FFFF00' :
                record.typeActivity === 'Rojo' ? '#FF0000' :
                  record.typeActivity === 'Azul' ? '#0000FF' :
                    record.typeActivity === 'Naranja' ? '#FFA500' :
                      record.typeActivity === 'Negro' ? '#000000' :
                        record.typeActivity === 'Violeta' ? '#800080' :
                          '#000',
          }}
          onClick={() => handleCircleClick(record)}
        />
      ),
    }
  ];

  const handleCircleClick = (record) => {
    // Puedes pasar la información necesaria al modal, por ejemplo, record
    // Puedes almacenar el record en un estado si es necesario para el modal
    setSelectedRecord(record);
    setIsModalEditDayVisible(true);
  };

  const handlePrevDay = () => {
    const newSelectedDate = new Date(selectedDate);
    newSelectedDate.setDate(selectedDate.getDate() - 1);
    setSelectedDate(newSelectedDate);
  };

  const handleNextDay = () => {
    const newSelectedDate = new Date(selectedDate);
    newSelectedDate.setDate(selectedDate.getDate() + 1);
    setSelectedDate(newSelectedDate);
  };

  const groupedActivities = groupAndSummarizeActivities(activities?.activities);

  const columnsTotales = [
    {
      title: "Tipo de actividad",
      dataIndex: "typeActivity",
      key: "typeActivity",
      align: 'center',
      width: 200,

      render: (text) => {
        const activity = TypeActivity.find(item => item.value === text);
        return (
          <span style={{ fontSize: "12px" }}>
            {activity ? `${activity.label} ${activity.value} ${activity.label}` : text}
          </span>
        );
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: 'center',
      render: (text) => <span style={{ fontSize: "12px" }}>{text}</span>,
    },
    {
      title: "Minutos",
      dataIndex: "duration",
      key: "duration",
      align: 'center',
      render: (text) => <span style={{ fontSize: "12px" }}>{text}</span>,
    },
    {
      title: "Horas",
      dataIndex: "duration",
      key: "duration",
      align: 'center',
      render: (text) => {
        const hours = Math.floor(text / 60);
        const minutes = text % 60;

        return (
          <span style={{ fontSize: '11px' }}>
            {hours}:{minutes < 10 ? '0' : ''}{minutes}
          </span>
        );
      },
    },
  ];

  return (
    <div className='form-organice'>
      <div className='header-profile-name'>
        <div className='nav-header'>
          <BsArrowLeftShort onClick={() => navigate(-1)} />
        </div>
        <div>Organiza tu día</div>
      </div>
      <form>
        <h3>Actividad:</h3>
        <select
          name='description'
          value={newActivity.description}
          onChange={handleInputChange}
        >
          <option value='' disabled>Seleccione actividad</option>
          {motiveReunion.map((opcion) => (
            <option key={opcion.key} value={opcion.label}>
              {opcion.label}
            </option>
          ))}
        </select>
        <h3>Con quien?</h3>
        <input
          type='text'
          name='person'
          value={newActivity.person}
          onChange={handleInputChange}
        />
        <h3>Hora, fecha y duracióm </h3>
        <input
          style={{ width: '20%' }}
          type='time'
          name='time'
          value={newActivity.time}
          onChange={handleInputChange}
        />
        <input
          style={{ width: '30%', marginLeft: '10px' }}
          type='date'
          name='date'
          value={newActivity.date}
          onChange={handleInputChange}
        />
        <input
          style={{ width: '20%', marginLeft: '10px' }}
          type="number"
          name='duration'
          value={newActivity.duration}
          placeholder='Minutos'
          onChange={handleInputChange}
        />

        <h3>Tipo de actividad</h3>
        <select
          name='typeActivity'
          value={newActivity.typeActivity}
          onChange={handleInputChange}
        >
          <option value='' disabled>Seleccione actividad</option>
          {TypeActivity.map((opcion) => (
            <option key={opcion.key} value={opcion.value}>
              {opcion.label + '' + opcion.description}
            </option>
          ))}
        </select>

        <Button type='primary' className='ant-btn-folow' onClick={handleAddActivity}>
          Agregar Actividad
        </Button>
      </form>

      <h3>Actividades del día:</h3>


      <div className='selectDay'>
        <span style={{ marginRight: '10px' }}>Anterior</span>
        <BsArrowLeftShort className='row-icon' onClick={handlePrevDay} />
        <BsArrowRightShort className='row-icon' onClick={handleNextDay} />
        <span style={{ marginLeft: '10px' }}>Siguiente</span>
      </div>
      <Table dataSource={filteredActivities} columns={columns} rowKey='timestamp' className='table-day' />
      
      {groupedActivities.map((group, index) => (
        <div key={index} className='table-two'>
          <h2>{group.month}</h2>
          <span>Acciones del mes</span>
          <Table dataSource={group.activities} columns={columnsTotales} className='table-schudule-2' pagination={false} />
        </div>
      ))}
      <ModalEditDay closeModal={() => setIsModalEditDayVisible(false)} visible={isModalEditDayVisible} record={selectedRecord} />

    </div>
  );
}
