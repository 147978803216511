import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { CalendarOutlined, HomeOutlined, UserOutlined } from "@ant-design/icons";
import "./Footer.scss";
import { Modal } from "antd";
import Schedule from "../Schedule/Schedule";

const Footer = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  let location = useLocation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleClick = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (!user) {
    // Si no hay usuario, no renderizar el footer
    return null;
  }

  return (
    <div className="footer">
      {location.pathname !== "/login" &&
        location.pathname !== "/register" &&
        location.pathname !== "/" && (
        <nav>
          <div className="inicio">
            <Link to="/wellcome">
              <HomeOutlined className="icon-home" />
            </Link>
            inicio
          </div>

          <div className="schudule">
            <CalendarOutlined
              alt="Chat"
              className="icon-schudule"
              onClick={handleClick}
            />
            Agenda
          </div>

          <div className="perfil">
            <Link to="/profile">
              <UserOutlined className="icon-user" />
            </Link>
            Mi perfil
          </div>
        </nav>
      )}
      <Modal
        title="Mi agenda"
        footer={null}
        open={isModalVisible}
        onCancel={handleCancel}
        wrapClassName={
          isModalVisible ? "animated-modal-up" : "animated-modal-down"
        }
        className="border-radius-modal"
      >
        <Schedule closeModal={handleCancel} />
      </Modal>
    </div>
  );
};

export default Footer;
