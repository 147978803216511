import { Button, Spin } from 'antd';
import React from 'react';
import { BsArrowLeftShort } from 'react-icons/bs';
import { FaUserCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ClientInactive = () => {
  const navigate = useNavigate();
  const { clients } = useSelector((state) => state.clients);

  if (!clients?.clients || clients.clients.length === 0) {
    return (
      <div>
        <p>No hay clientes disponibles.</p>
      </div>
    );
  }

  if (!clients?.clients || !clients?.clients.length) {
    return (
      <div>
        <p className="text-search">Buscando sus clientes. Aguarde por favor</p>
        <Spin tip="Buscando" size="large" style={{ margin: 50 }} />
      </div>
    );
  }

  const inactiveClients = clients.clients
    .filter((client) => client?.status === false)
    .sort((a, b) => a.lastname.localeCompare(b.lastname));

  return (
    <div>
      <div className="header-profile-name">
        <div className="nav-header">
          <BsArrowLeftShort onClick={() => navigate(-1)} />
        </div>
        <div className="text-header">Estos son tus clientes inactivos</div>
      </div>

      {inactiveClients.length > 0 ? (
        inactiveClients.map((client, id) => (
          <div key={id} className="container-client-many">
            <div onClick={() => navigate(`/clients/${client._id}`)} className="client-many">
              {client?.status === true ? (
                <FaUserCircle className="icon-client-many-active" />
              ) : (
                <FaUserCircle className="icon-client-many-inactive" />
              )}
              <p>
                {client.name}, {client.lastname}{" "}
              </p>
              <br />
            </div>
          </div>
        ))
      ) : (
        <>
          <p>No tienes clientes inactivos en este momento.</p>
          <Button onClick={() => navigate("/dashboard")}>Volver al dashboard </Button>
        </>
      )}
    </div>
  );
};

export default ClientInactive;
