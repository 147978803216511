import dayjs from "dayjs";

export const generadorCuando = function (fecha, format = "DD/MM/YYYY HH:mm") {

	if (!fecha) fecha = dayjs();

	fecha = dayjs(fecha);
	if (!fecha.isValid()) return null;


	const ts = Math.ceil(fecha.valueOf() / 1000);

	if (Number.isNaN(ts)) return {
		ts: null,
		str: null,
	};

	const str = fecha.format(format);
	return {
		ts: ts,
		str: str,
	};

};


export const groupAndSummarizeActivities = (activities) => {
  if (!activities || activities.length === 0) {
    return [{ message: 'No hay actividades disponibles.' }];
  }

  const groupedActivities = activities.reduce((result, activity) => {
    const { _id, typeActivity, duration, date } = activity;
    const month = new Date(date).toLocaleString('es-ES', { month: 'long' }).toUpperCase();

    if (!result[month]) {
      result[month] = {
        month,
        activities: [],
      };
    }

    const typeActivityEntry = result[month].activities.find(entry => entry.typeActivity === typeActivity);

    if (!typeActivityEntry) {
      result[month].activities.push({
        _id: typeActivity,
        typeActivity,
        total: 1,
        duration: duration || 0,
      });
    } else {
      typeActivityEntry.total += 1;
      typeActivityEntry.duration += duration || 0;
    }

    return result;
  }, {});

  return Object.values(groupedActivities).map(({ month, activities }) => ({
    month,
    activities,
  }));
};
