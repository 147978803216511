import React from "react";
import Login from "../Login/Login";
import Wellcome from "../Wellcome/Wellcome";





const Home = () => {
    const user = JSON.parse(localStorage.getItem("user"));



  if (!user) {
    return <Login />;
  }

  return (
    <>
      <Wellcome />
    </>
  );
};

export default Home;
