import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, notification } from "antd";
import { BsArrowLeftShort } from "react-icons/bs";
import { TbHandClick } from "react-icons/tb";
import { GiPresent } from "react-icons/gi";
import { BiPhoneCall } from "react-icons/bi";
import { MdOutlineWavingHand } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { createFollowUpActions, reset } from "../../features/data/dataSlice";
import "./FollowUpActions.scss"
import { generadorCuando } from "../../utils/funtions";
const FollowUpActions = () => {
  const { client } = useSelector((state) => state.clients);
  const navigate = useNavigate();
  const { _id } = useParams();
  const { isError, isSuccess, message } = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const id = _id;

  // Estado global
  const [stateFollowAction, setStateFollowAction] = useState({
    call: { observations: "", contactDate: "" },
    rememberDate: { remember: "", dateToRemember: "" },
    regards: { greetingsType: "", dateOfGreetings: "" },
    popBy: { descripcion: "", datepopBy: "", value: "" },
  });

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: "Felicitaciones",
        description: "Informacion agregada con éxito",
      });
    }
    if (isError) {
      notification.error({ message: "Error", description: message });
    }
    dispatch(reset());
  }, [isSuccess, isError, message]);

  const onChange = (e) => {
    const { name, value, dataset: { key } } = e.target;

    // Actualizar solo la propiedad correspondiente del estado global
    setStateFollowAction((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], [key]: value },
    }));
  };



  const onSubmit = (e) => {
    e.preventDefault();

    const changedData = [];

    // Lógica para cada tipo de acción
    if (stateFollowAction.call.observations || stateFollowAction.call.contactDate) {
      changedData.push({
        call: {
          observations: stateFollowAction.call.observations,
          contactDate: generadorCuando(stateFollowAction.call.contactDate).ts,
        },
      });
    }

    if (stateFollowAction.rememberDate.remember || stateFollowAction.rememberDate.dateToRemember) {
      changedData.push({
        rememberDate: {
          remember: stateFollowAction.rememberDate.remember,
          dateToRemember: generadorCuando(stateFollowAction.rememberDate.dateToRemember).ts,
        },
      });
    }

    if (stateFollowAction.regards.greetingsType || stateFollowAction.regards.dateOfGreetings) {
      changedData.push({
        regards: {
          greetingsType: stateFollowAction.regards.greetingsType,
          dateOfGreetings: generadorCuando(stateFollowAction.regards.dateOfGreetings).ts,
        },
      });
    }

    if (stateFollowAction.popBy.descripcion || stateFollowAction.popBy.datepopBy || stateFollowAction.popBy.value) {
      changedData.push({
        popBy: {
          descripcion: stateFollowAction.popBy.descripcion,
          datepopBy: generadorCuando(stateFollowAction.popBy.datepopBy).ts,
          value: stateFollowAction.popBy.value,
        },
      });
    }

    // Enviar los datos de seguimiento al backend solo si ha habido cambios
    if (changedData.length > 0) {
      const followUpData = {
        clientId: id,
        ...changedData.reduce((acc, item) => ({ ...acc, ...item }), {}),
      };
      dispatch(createFollowUpActions(followUpData));
    }

    // Redirigir después de 2 segundos
    setTimeout(() => {
      navigate(-1);
    }, 2000);
  };

  return (
    <div className="form-folowUp">
      <div className="header-profile-name">
        <div className="nav-header">
          <BsArrowLeftShort onClick={() => navigate(-1)} /> <AiOutlineUser onClick={() => navigate(`/clientprofile/${client._id}`)} />
        </div>
        <div>Cliente - {client?.name} {client?.qualification?.[0]?.qualification || 'Sin calificación'}</div>
      </div>
      <form onSubmit={onSubmit}>
        <div >
          <h3>Llamadas <BiPhoneCall /></h3>
          <input
            type="text"
            name="call"
            data-key="observations"
            value={stateFollowAction.call.observations}
            placeholder="Observaciones"
            onChange={onChange}
          />
          <input
            type="date"
            name="call"
            data-key="contactDate"
            value={stateFollowAction.call.contactDate}
            placeholder="Fecha del contacto"
            onChange={onChange}
          />
        </div>

        <div>
          <h3>Fechas para recordar <TbHandClick /></h3>
          <input
            type="text"
            name="rememberDate"
            data-key="remember"
            value={stateFollowAction.rememberDate.remember}
            placeholder="Que quieres recordar?"
            onChange={onChange}
          />
          <input
            type="text"
            name="rememberDate"
            data-key="dateToRemember"
            value={stateFollowAction.rememberDate.dateToRemember}
            placeholder="Fecha para recordar DD/MM"
            onChange={onChange}
            max="9999-12-31" // Establece una fecha máxima
            pattern="\d{2}/\d{2}" // Patrón para validar la entrada (día/mes)
            />
        </div>

        <div >
          <h3>Saludos <MdOutlineWavingHand /> </h3>
          <input
            type="text"
            name="regards"
            data-key="greetingsType"
            value={stateFollowAction.regards.greetingsType}
            placeholder="Que acontecimiento quieres recordar?"
            onChange={onChange}
          />
          <input
            type="date"
            name="regards"
            data-key="dateOfGreetings"
            value={stateFollowAction.regards.dateOfGreetings}
            placeholder="Fecha del acontecimiento"
            onChange={onChange}
          />
        </div>
        <div >
          <h3>Pop By - Presentes <GiPresent /> </h3>
          <input
            type="text"
            name="popBy"
            data-key="descripcion"
            value={stateFollowAction.popBy.descripcion}
            placeholder="Cual fue el presente??"
            onChange={onChange}
          />
          <input
            type="date"
            name="popBy"
            data-key="datepopBy"
            value={stateFollowAction.popBy.datepopBy}
            placeholder="Fecha del presente"
            onChange={onChange}
          />
          <input
            className="value-input"
            type="number"
            name="popBy"
            data-key="value"
            value={stateFollowAction.popBy.value}
            placeholder="€"
            onChange={onChange}
          />
        </div>
        <Button type="primary" block htmlType="submit" className="ant-btn-folow">
          Agregra datos de seguimiento
        </Button>
      </form>
    </div>
  );
};

export default FollowUpActions;

