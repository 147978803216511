import { Calendar } from "antd";
import React, { useEffect } from "react";
import { FcCalendar, FcServices, FcVoicePresentation } from "react-icons/fc";
import Logo from "../../assets/img/logo-con-letras.png";
import "./Wellcome.scss";
import { useSelector } from "react-redux";

const Wellcome = () => {
  const user = JSON.parse(localStorage.getItem("user"));


  return (
    <div className="wellcome">
      <div className="saludo">
        <span className="nombre">Hola {user?.admin?.username} !</span>
        <img src={Logo} alt="Logo-G-free" className="logo" />

      </div>

      <div className="botones">
        <a href="/dashboard/">
          <div className="btn-clients">
            <p>Dashboard</p>
            <FcServices className="icon-btn"/>
          </div>
        </a>

        <a href="/addclient/">
          <div className="btn-clients">
            <p>Cargar cliente</p>
            <FcVoicePresentation className="icon-btn"/>
          </div>
        </a>

        <a href="/organizemyday/">
          <div className="btn-clients">
            <p>Organizar mi día </p>
            <FcCalendar className="icon-btn"/>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Wellcome;
