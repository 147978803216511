export const fuentes = [
  {key: 0, value: 0, label: "Facebook" },
  {key: 1, value: 1, label: "Instagram" },
  {key: 2, value: 2, label: "Google" },
  {key: 3, value: 3, label: "Referido" },
  {key: 4, value: 4, label: "Volador" },
  {key: 5, value: 5, label: "Otro" },
]

export const tyoeProperty = [
  {key: 0, value: 0, label: "Casa" },
  {key: 1, value: 1, label: "Piso" },
  {key: 2, value: 2, label: "Solar" },
  {key: 3, value: 3, label: "Local" },
  {key: 4, value: 4, label: "Oficina" },
  {key: 5, value: 5, label: "Otro" },
]

export const typeClient = [
  {key: 0, value: 0, label: "Propietario" },
  {key: 1, value: 1, label: "Inquilino" },
  {key: 2, value: 2, label: "Propietario e Inquilino" },
  {key: 3, value: 3, label: "Otro" },
]

export const typeoperation = [
  {key: 0, value: 0, label: "Venta" },
  {key: 1, value: 1, label: "Alquiler" },
  {key: 2, value: 2, label: "Alquiler Temporal" },
  {key: 3, value: 3, label: "Otro" },
]

export const city = [
  {key: 0, value: 0, label: "Madrid" },
  {key: 1, value: 1, label: "Barcelona" },
  {key: 2, value: 2, label: "Valencia" },
  {key: 3, value: 3, label: "Sevilla" },
  {key: 4, value: 4, label: "Zaragoza" },
  {key: 5, value: 5, label: "Málaga" },
  {key: 6, value: 6, label: "Murcia" },
  {key: 7, value: 7, label: "Palma de Mallorca" },
  {key: 8, value: 8, label: "Las Palmas de Gran Canaria" },
  {key: 9, value: 9, label: "Bilbao" },
  {key: 10, value: 10, label: "Otro" },
]

export const statusProcess = [
  { id: 0, key: 0, label: "Captación", description: "Etapa de captación de propiedades para la venta." },
  { id: 1, key: 1, label: "Evaluación", description: "Evaluación de la propiedad para determinar su valor de mercado." },
  { id: 2, key: 2, label: "Publicación", description: "Publicación de la propiedad en diferentes plataformas." },
  { id: 3, key: 3, label: "En proceso de reserva", description: "La propiedad está en proceso de reserva." },
  { id: 4, key: 4, label: "Reservado", description: "La propiedad ha sido reservada." },
  { id: 5, key: 5, label: "En proceso de firma de arras", description: "Firma del contrato de arras." },
  { id: 6, key: 6, label: "Firmado", description: "El contrato de arras ha sido firmado." },
  { id: 7, key: 7, label: "Verificación legal", description: "Verificación de la situación legal de la propiedad." },
  { id: 8, key: 8, label: "Preparación de escritura", description: "Preparación de la escritura de compraventa." },
  { id: 9, key: 9, label: "Firma de escritura", description: "Firma de la escritura de compraventa ante notario." },
  { id: 10, key: 10, label: "En proceso de pago", description: "La propiedad está en proceso de pago." },
  { id: 11, key: 11, label: "Pagado", description: "La propiedad ha sido pagada en su totalidad." },
  { id: 12, key: 12, label: "Entrega de llaves", description: "Entrega de las llaves al nuevo propietario." },
  { id: 13, key: 13, label: "Postventa", description: "Seguimiento y atención postventa." },
];

export const motiveReunion = [
  {key: 0, value:0, label: 'Reunion de captación'},
  {key: 1, value:1, label: 'Reunion de seguimiento'},
  {key: 2, value:2, label: 'Reunion de firma'},
  {key: 3, value:3, label: 'Reunion de entrega'},
  {key: 4, value:4, label: 'Reunion de postventa'},
  {key: 5, value:5, label: 'Cafe'},
  {key: 6, value:6, label: 'Almuerzo'},
  {key: 7, value:7, label: 'Cena'},
  {key: 8, value:8, label: 'Presentación'},
  {key: 9, value:9, label: 'Team building'},
  {key: 10, value:10, label: 'Capacitación'},
  {key: 11, value:11, label: 'Otro'},
]

export const TypeActivity = [
  {key: 0, value: 'Verde', label: '🟢', description: 'Productiva'},
  {key: 1, value: 'Amarillo', label: '🟡', description: 'Puente, Planificación, Administrativa y Formativa'},
  {key: 2, value: 'Rojo', label: '🔴', description: 'Personal'},
  {key: 3, value: 'Azul', label: '🔵', description: 'Vacaciones'},
  {key: 4, value: 'Naranja', label: '🟠', description: 'Enfermedad'},
  {key: 5, value: 'Negro', label: '⚫', description: 'Baja'},
  {key: 6, value: 'Violeta', label: '🟣', description: 'Otro'},
]