import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import "./Dashboard.scss";
import { getAllClientsByAdmin } from "../../features/client/clientSlice";
import { Spin } from "antd";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clients, isLoading } = useSelector((state) => state.clients);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      // Dispatch la acción para cargar los clientes
      dispatch(getAllClientsByAdmin());

      // Simular un retraso de 1 segundo antes de mostrar el contenido
      await new Promise((resolve) => setTimeout(resolve, 300));

      // Mostrar el contenido después del retraso
      setShowContent(true);
    };

    fetchData();
  }, [dispatch]);

  if (isLoading || !showContent) {
    return (
      <div>
        <p className="text-search">Buscando sus clientes. Aguarde por favor</p>
        <Spin tip="Buscando" size="large" style={{ margin: 50 }} />
      </div>
    );
  }

  return (
    <div className="container-dasboard " >
      <div className="header-profile-name">
        <div className="nav-header">
          <BsArrowLeftShort onClick={() => navigate(-1)} />
        </div>
        <div>Que necesitas hacer?</div>
      </div>
      <div className="container-btn-dasboard">
        <div className="container-btn-sup">
          <div>
            <button
              className="btn-dash-tl"
              onClick={() => navigate("/clients/")}
            >
              <p>Todos mis</p> <p>clientes</p>
            </button>
          </div>
          <div>
            <button
              className="btn-dash-tr"
              onClick={() => navigate("/clientsfollow/")}
            >
              <p>Clientes en</p> <p>seguimiento</p>
            </button>
          </div>
        </div>
        <div className="container-btn-cen">
          <div className="container-btn-cen">
            <p className="text-center"> Total de clientes: {clients?.clients?.length}</p>
          </div>
        </div>
        <div className="container-btn-inf">
          <div>
            <button className="btn-dash-dl"
              onClick={() => navigate("/clientsinactive/")}
            >
              <p>Clientes</p> <p> inactivos </p>
            </button>
          </div>
          <div>
            <button className="btn-dash-dr" onClick={() => navigate("/salesprocess")}>
              <p>Ventas</p> <p> en proceso </p>


            </button>
          </div>
        </div>
      </div>
      <div className="container-bottom-buttons">
        <span>Estamos desarrollando estas nuevas funciones</span>
        <span>en breve más herramientas para tu negocio</span>
        <div>
          <button className="bottom-button"
          // onClick={() => navigate("/billing")}
          >
            <p>Mi facturación</p>
          </button>
        </div>
        <div>
          <button className="bottom-button"
          // onClick={() => navigate("/commissions")}
          >
            <p>Mis comisiones</p>
          </button>
        </div>
        <div>
          <button className="bottom-button"
          //  onClick={() => navigate("/statistics")}
          >
            <p>Mis estadísticas</p>
          </button>
        </div>
        <div>
          <button className="bottom-button"
            onClick={() => navigate("/organizemyday/")}
          >
            <p>Cargar actividad</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
