import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { generadorCuando, groupAndSummarizeActivities } from '../../utils/funtions';
import { useDispatch, useSelector } from 'react-redux';
import { getAllActivities } from '../../features/data/dataSlice';
import './Schedule.scss';
import { FaCircle } from 'react-icons/fa';
import { TypeActivity } from '../../utils/diccionario';

const Schedule = ({ closeModal }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const { activities } = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    dispatch(getAllActivities());
  }, [dispatch]);

  const handleOrganizeDay = () => {
    closeModal();
    navigate("/organizemyday");
  };



  const columns = [
    {
      title: 'Día',
      dataIndex: 'date',
      key: 'date',
      render: (text) => (
        <span style={{ fontSize: '10px' }}>
          {text ? generadorCuando(text, 'DD/MM').str : 'No Aplica'}
        </span>
      ),
      width: 50,
      align: 'center'
    },
    {
      title: 'Hora',
      dataIndex: 'time',
      key: 'time',
      render: (text) => <span style={{ fontSize: '11px' }}>{text}</span>,
      width: 50,
      align: 'center'
    },
    {
      title: 'Actividad',
      dataIndex: 'description',
      key: 'description',
      render: (text) => <span style={{ fontSize: '12px' }}>{text}</span>,
      width: 100,
      align: 'center'
    },
    {
      title: 'Persona',
      dataIndex: 'person',
      key: 'person',
      render: (text) => <span style={{ fontSize: '12px' }}>{text}</span>,
      width: 50,
      align: 'center'
    },
    {
      title: 'Tipo',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (x, record) => (
        <FaCircle
          style={{
            fontSize: '10px',
            border: '1px solid gray',
            borderRadius: '50%',
            color: record.typeActivity === 'Verde' ? '#00FF00' :
              record.typeActivity === 'Amarillo' ? '#FFFF00' :
                record.typeActivity === 'Rojo' ? '#FF0000' :
                  record.typeActivity === 'Azul' ? '#0000FF' :
                    record.typeActivity === 'Naranja' ? '#FFA500' :
                      record.typeActivity === 'Negro' ? '#000000' :
                        record.typeActivity === 'Violeta' ? '#800080' :
                          '#000',

          }}
        />
      ),
    }
  ];

  const filteredActivities = activities?.activities?.filter(activity => {
    const activityDate = new Date(activity.date);
    return activityDate.toDateString() === selectedDate.toDateString();
  }) || [];

  const groupedActivities = groupAndSummarizeActivities(activities?.activities);


  const columnsTotales = [
    {
      title: "Tipo de actividad",
      dataIndex: "typeActivity",
      key: "typeActivity",
      align: 'center',
      width: 200,

      render: (text) => {
        const activity = TypeActivity.find(item => item.value === text);
        return (
          <span style={{ fontSize: "12px" }}>
            {activity ? `${activity.value} - ${activity.label}` : text}
          </span>
        );
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: 'center',
      render: (text) => <span style={{ fontSize: "12px" }}>{text}</span>,
    },
    {
      title: "Minutos",
      dataIndex: "duration",
      key: "duration",
      align: 'center',
      render: (text) => <span style={{ fontSize: "12px" }}>{text}</span>,
    },
    {
      title: "Horas",
      dataIndex: "duration",
      key: "duration",
      align: 'center',
      render: (text) => {
        const hours = Math.floor(text / 60);
        const minutes = text % 60;
      
        return (
          <span style={{ fontSize: '11px' }}>
            {hours}:{minutes < 10 ? '0' : ''}{minutes}
          </span>
        );
      },
    },
  ];

  return (
    <div className='container-schudule'>
      {filteredActivities.length === 0 ? (
        <div className='no-activities-message'>
          <p>No tienes actividades programadas para este día.</p>
        </div>
      ) : (
        <Table dataSource={filteredActivities} columns={columns} rowKey='timestamp' className='table-schudule' />
      )}

      {groupedActivities.map((group, index) => (
        <div key={index}>
          <h2>{group.month}</h2>
          <Table dataSource={group.activities} columns={columnsTotales} className='table-schudule-2' pagination={false} />
        </div>
      ))}

      <Button className='btn-edit-day' onClick={handleOrganizeDay}  >Organizar mi día</Button>
    </div>
  );
};

export default Schedule;
