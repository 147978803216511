
// dataService.js
import axios from "axios";

const API_URL = process.env.REACT_APP_BACK_URL;


const user = JSON.parse(localStorage.getItem("user"));

const createPersData = async (clientPersData) => {
  const res = await axios.post(API_URL + "/data/createPersData", clientPersData);
  return res.data;
};
const createFollowUpActions = async (clientfollowA) => {
  const res = await axios.post(API_URL + "/followupactions/createFollowUpActions", clientfollowA);
  return res.data;
};
const createFamilyData = async (familyData) => {
  const res = await axios.post(API_URL + "/familyData/createFamilyData", familyData);
  return res.data;
};
const createQualification = async (clientQualiData) => {
  const res = await axios.post(API_URL + "/qualifications/createQualification", clientQualiData);
  return res.data;
};
const createStatus = async (clientId, data) => {
  const res = await axios.post(API_URL + "/status/createStatus", {
    clientId,
    data
  });
  return res.data;
};
const UpdateStatus = async (data) => {
  const { status, client } = data;
  const statusInfo = { status, client }
  const res = await axios.put(API_URL + "/status/UpdateStatus", statusInfo, {
  });
  return res.data;
};

const createNewActivity = async (data) => {
  try {
    const res = await axios.post(
      API_URL + "/newActivity/createNewActivity",
      data,
      {
        headers: {
          Authorization: user?.token, // Asegúrate de que user.token tenga el token correcto
        },
      }
    );
    return res.data;
  } catch (error) {
    console.error("Error al realizar la solicitud:", error);
    throw error;
  }
};

const updateActivity = async ({_id, data}) => {
  try {
    const res = await axios.put(
      API_URL + "/newActivity/updateActivity/" + _id,
      data,
      {
        headers: {
          Authorization: user?.token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.error("Error al realizar la solicitud:", error);
    throw error;
  }
}

const updateQualification = async ({_id, data}) => {
  try {
    const res = await axios.put(
      API_URL + "/qualifications/updateQualification/" + _id,
      data,
      {
        headers: {
          Authorization: user?.token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.error("Error al realizar la solicitud:", error);
    throw error;
  }
}

const getAllActivities = async () => {
  try {
    const res = await axios.get(API_URL + "/newActivity/getAllActivities", {
      headers: {
        Authorization: user?.token,
      },
    });
    return res.data;
  } catch (error) {
    console.error("Error al realizar la solicitud:", error);
    throw error;
  }
};

const dataService = {
  createPersData,
  createFollowUpActions,
  createFamilyData,
  createQualification,
  createStatus,
  UpdateStatus,
  createNewActivity,
  getAllActivities,
  updateActivity,
  updateQualification
}

export default dataService;
