import React, { useEffect, useState } from "react";
import { Button, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createFamilyData, reset } from "../../features/data/dataSlice";
import { BsArrowLeftShort, BsPersonPlus } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";
import { ImUsers } from "react-icons/im";
import { GiLinkedRings } from "react-icons/gi";
import "./AddFamilyData.scss";
import { updateFamilyData } from "../../features/client/clientSlice";

const AddFamilyData = () => {
  const { client } = useSelector((state) => state.clients);
  const { _id } = useParams();
  const { isError, isSuccess, message } = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [numChildren, setNumChildren] = useState(1);

  const [formValues, setFormValues] = useState({
    couple: "",
    children: client?.familyData?.children.map(child => child || "") || [""],
  });

  const { couple, children } = formValues;

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: "Felicitaciones",
        description: "Información agregada con éxito",
      });

      setFormValues({
        couple: "",
        children: Array.from({ length: numChildren }, () => ""),
      });

      setTimeout(() => {
        navigate(-1);
      }, 2000);
    }

    if (isError) {
      notification.error({ message: "Error", description: message });
    }

    dispatch(reset());
  }, [isSuccess, isError, message, numChildren, dispatch, navigate]);

  const onChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const updateChildName = (e, index) => {

    const newChildren = [...children];
    newChildren[index] = e.target.value;


    setFormValues((prevState) => ({
      ...prevState,
      children: newChildren,
    }));

  };


  const updateNumChildren = (num) => {
    const newChildren = [...children];
    while (newChildren.length < num) {
      newChildren.push("");
    }
    newChildren.length = num;
    setFormValues((prevState) => ({
      ...prevState,
      children: newChildren,
    }));
    setNumChildren(num);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (client?.familyData) {
      dispatch(updateFamilyData({ _id: client._id, familyData: formValues }));
    } else {
      dispatch(createFamilyData({ ...formValues, clientId: _id }));
    }

    setFormValues({
      couple: "",
      children: Array.from({ length: numChildren }, () => ""),
    });

    setTimeout(() => {
      navigate(-1);
    }, 2000);
  };

  useEffect(() => {
    if (client?.familyData?.children.length > 1) {
      setFormValues((prevState) => ({
        ...prevState,
        children: client.familyData.children.map(child => child || ""),
      }));
      setNumChildren(client.familyData.children.length);
    }
  }, []);


  return (
    <div className="addClient-data">
      <div className="header-profile-name">
        <div className="nav-header">
          <BsArrowLeftShort onClick={() => navigate(-1)} />{" "}
          <AiOutlineUser onClick={() => navigate(`/clientprofile/${client._id}`)} />
        </div>
        <div>Cliente - {client?.name} {client?.qualification?.[0]?.qualification || 'Sin calificación'}</div>
      </div>
      <div>
        <div className="information-add">
          {client?.familyData?.couple && (
            <div className="btn-icon-client">
              <GiLinkedRings /> Pareja: {client?.familyData?.couple}
            </div>
          )}
          {client?.familyData?.children.length > 0 && (
            <div className="btn-icon-client">
              <ImUsers /> {`Hij${client?.familyData?.children.length === 1 ? 'o' : 'os'}`} -{' '}
              {client?.familyData?.children.map((child, index) => {
                if (index === client.familyData.children.length - 1) {
                  return child;
                } else {
                  return child + ', ';
                }
              })}
            </div>
          )}
        </div>
        <form className="addClient-form" onSubmit={onSubmit}>
          <h2>Agregar los datos Familiares de {client?.name}</h2>
          <input
            type="text"
            name="couple"
            value={couple}
            placeholder={client?.familyData?.couple ? client?.familyData?.couple : "Nombre de la esposa"}
            onChange={onChange}
          />
          {Array.from({ length: numChildren }).map((_, index) => (
            <input
              key={index}
              type="text"
              name={`children[${index}]`}
              value={index < formValues.children.length ? formValues.children[index] : ""}
              placeholder={`Nombre del hijo/a ${index + 1}`}
              onChange={(e) => updateChildName(e, index)}
            />
          ))}
          <div className="icon-children">
            <BsPersonPlus
              onClick={() => updateNumChildren(numChildren + 1)}
              className="icon-more-children"
            />
          </div>

          <Button type="primary" block htmlType="submit" className="ant-btn">
            {client?.familyData ? "Editar información familiar" : "Agregar datos familiares"}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default AddFamilyData;
