import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { BsArrowLeftShort } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { createPropertyForClient, getClientById } from '../../features/client/clientSlice';
import { city, statusProcess, tyoeProperty, typeoperation } from '../../utils/diccionario';
import './AddProperty.scss';

export default function AddProperty() {
  const navigate = useNavigate();
  const { _id } = useParams();
  const dispatch = useDispatch();
  const { client } = useSelector((state) => state.clients);

  const [formData, setFormData] = useState({
    clientId: _id,
    name: '',
    type: '', // <-- Definir tipo de propiedad
    city: '', // <-- Definir ciudad
    typeOperation: '', // <-- Definir tipo de operación
    statusProcess: '' // <-- Definir estado de la propiedad
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(createPropertyForClient(formData));
    setFormData({
      clientId: _id,
      name: '',
      type: '', // <-- Restablecer tipo de propiedad
      city: '', // <-- Restablecer ciudad
      typeOperation: '', // <-- Restablecer tipo de operación
      statusProcess: '' // <-- Restablecer estado de la propiedad
    });
    setTimeout(() => {
      navigate(-1);
    }, 2000);
  };

  useEffect(() => {
    dispatch(getClientById(_id));
  }, [_id, dispatch]);

  return (
    <div className="addClient-data">
      <div className="header-profile-name">
        <div className="nav-header">
          <BsArrowLeftShort onClick={() => navigate(-1)} /> <AiOutlineUser onClick={() => navigate(`/clientprofile/${client._id}`)} />
        </div>
        <div>Cliente - {client?.name} {client?.qualification?.[0]?.qualification || 'Sin calificación'}</div>
      </div>
      <div>
        <form className="addProperty-form" onSubmit={onSubmit}>
          <h2>Agregar los datos de la propiedad</h2>

          <input
            type="text"
            name="name"
            value={formData.name}
            placeholder="Nombre descriptivo de la propiedad"
            onChange={handleChange}
          />
          <select
            name="type"
            value={formData.type}
            onChange={handleChange}
          >
            <option value="" disabled>Seleccione tipo de propiedad</option>
            {tyoeProperty.map((opcion) => (
              <option key={opcion.key} value={opcion.label}>
                {opcion.label}
              </option>
            ))}
          </select>
          <select
            name="city"
            value={formData.city}
            onChange={handleChange}
          >
            <option value="" disabled>Seleccione ciudad</option>
            {city.map((opcion) => (
              <option key={opcion.key} value={opcion.label}>
                {opcion.label}
              </option>
            ))}
          </select>
          <select
            name="typeOperation"
            value={formData.typeOperation}
            onChange={handleChange}
          >
            <option value="" disabled>Seleccione tipo de operación</option>
            {typeoperation.map((opcion) => (
              <option key={opcion.key} value={opcion.label}>
                {opcion.label}
              </option>
            ))}
          </select>
          <select
            name="statusProcess"
            value={formData.statusProcess}
            onChange={handleChange}
          >
            <option value="" disabled>Seleccione estado de la propiedad</option>
            {statusProcess.map((opcion) => (
              <option key={opcion.key} value={opcion.label}>
                {opcion.label}
              </option>
            ))}
          </select>
          
          <Button type="primary" block htmlType="submit" className="ant-btn">
            Agregar propiedad del Cliente
          </Button>
        </form>
      </div>
    </div>
  );
}
