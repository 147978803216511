import { Button, Checkbox, Modal } from 'antd';
import React from 'react'

const Signaling = ({ modalVisibleS, onClose }) => {
  
  const tasksSignaling = [
    "Reserva revisada / Copia del DNI / Copia de reserva",
    "Cargar la reserva en Drive",
    "¿Se cerró? (Sí) = Refuerzo / (No) Devolución / Búsqueda opciones",
    "Refuerzo o devolución",
    "Renovar informes",
    "Fecha de firma / Horario + Tips mudanza al vendedor y comprador",
    "El escribano y enviar carpeta completa a escribano",
    "Libre deuda + Inventario",
    "Estado parcelario",
    "1 semana después!! Ver fecha y horario del boleto (agendar 2 días antes de la fecha de firma de boleto paso 5A)",
    "Pedir reserva con 48 hs anticipación / Armar factura (comprar regalo)",
    "Firma de boleto",
    "ITI o exención? COTI",
    "Seguimiento semanal de estos pasos!! De expediente sucesión",
    "Incorporación del bien al expediente",
    "Pago de honorarios del abogado",
    "Cumplido el artículo 21.716",
    "Pedido de expediente en préstamo",
    "Fecha de firma escritura / En",
    "Pedir reserva con 48 hs anticipación / Armar factura (comprar regalo)",
    "Firma de escritura",
    "Seguimiento / ¿Qué tal la experiencia? ¿La recomendarías?",
  ];
  
  
      
    
      return (
        <Modal
          title="Checklist de Publicación"
          open={modalVisibleS}
          onCancel={onClose}
          footer={[
            <Button key="back" onClick={onClose}>
              Cerrar
            </Button>,
          ]}
        >
          
            {tasksSignaling.map((task, index) => (
              <p key={index}>
                <Checkbox>{task}</Checkbox>
              </p>
            ))}
        </Modal>
      );
    };
    export default Signaling;
    