import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import clientService from "./clientService";

const initialState = {
  clients: [],
  client: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: "",
};

export const createClientByAdmin = createAsyncThunk(
  "clients/createClientByAdmin",
  async (clientData) => {
    try {
      return await clientService.createClientByAdmin(clientData);
    } catch (error) {
      console.error(error);
    }
  }
);
export const getClientById = createAsyncThunk(
  "clients/getClientById",
  async (_id) => {
    try {
      return await clientService.getClientById(_id);
    } catch (error) {
      console.error(error);
    }
  }
);
export const getAllClientsByAdmin = createAsyncThunk(
  "clients/getAllClientsByAdmin",
  async () => {
    try {
      const result = await clientService.getAllClientsByAdmin();
      return result;
    } catch (error) {
      console.error(error);
    }
  }
);
export const updateFamilyData = createAsyncThunk(
  "clients/updateFamilyData",
  async ({ _id, familyData }) => {
    try {
      const result = await clientService.updateFamilyData(_id, familyData);
      return result;
    } catch (error) {
      console.error(error);
    }
  }
);

export const createPropertyForClient = createAsyncThunk(
  "clients/createPropertyForClient",
  async (propertyData) => {
    try {
      const result = await clientService.createPropertyForClient(propertyData);
      return result;
    } catch (error) {
      console.error(error);
    }
  }
);

export const clientSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    reset: (state) => {
      state.isSuccess = false;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createClientByAdmin.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.client = action.payload;
      })

      .addCase(getAllClientsByAdmin.fulfilled, (state, action) => {
        state.clients = action.payload;
      })
      .addCase(getAllClientsByAdmin.pending, (state) => {
        state.clients = true;
      })
      .addCase(getClientById.fulfilled, (state, action) => {
        state.client = action.payload.client;
      })
      .addCase(updateFamilyData.fulfilled, (state, action) => {
        state.isSuccess = true;
      })
      .addCase(createPropertyForClient.fulfilled, (state, action) => {
        state.isSuccess = true;
      });
    // .addCase(getClientByname.fulfilled, (state, action) => {
    //   state.client = action.payload.client;
    // });
  },
});

export const { reset, isLoading } = clientSlice.actions;
export default clientSlice.reducer;
