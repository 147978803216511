import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllClientsByAdmin, reset } from "../../features/client/clientSlice";
import ClientFollow from "./ClientFollow/ClientFollow";

const ClientsFollow = () => {
  const dispatch = useDispatch();
  

  useEffect(() => {
    dispatch(getAllClientsByAdmin());
    dispatch(reset())
  }, []);
  
  return (
    <div>
      < ClientFollow/>
    </div>
  );
};

export default ClientsFollow;