import React, { useEffect, useState } from "react";
import { Button, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "./AddPersonalData.scss";
import { reset } from "../../features/data/dataSlice";
import { createPersData } from "../../features/data/dataSlice";
import { BsArrowLeftShort } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";

const AddPersonalData = () => {
  const { client } = useSelector((state) => state.clients);
  const { _id } = useParams();
  const { isError, isSuccess, message } = useSelector((state) => state.data);
  const [date, setDate] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clientPersData, setClientPersData] = useState({
    clientId: _id,
    fansteam: "",
    sport: "",
    sex: "",
    religion: "",
    titleprofession: "",
    celebrationDay: "",
  });
  const { fansteam, sport, sex, religion, titleprofession, celebrationDay } =
    clientPersData;

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: "Felicitaciones",
        description: "Informacion agregada con éxito",
      });
    }
    if (isError) {
      notification.error({ message: "Error", description: message });
    }
    dispatch(reset());
    // eslint-disable-next-line
  }, [isSuccess, isError, message]);

  const onChange = (e) => {
    setClientPersData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    const dateValue = e.target.value;
    const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;
    if (dateRegex.test(dateValue)) {
      setDate(dateValue);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(createPersData(clientPersData));
    setClientPersData({
      clientId: "",
      fansteam: "",
      sport: "",
      sex: "",
      religion: "",
      titleprofession: "",
      celebrationDay: "",
    });
    setTimeout(() => {
      navigate(-1);
    }, 2000);
  };

  return (
    <div className="addClient-data">
       <div className="header-profile-name">
        <div className="nav-header">
          <BsArrowLeftShort onClick={() => navigate(-1)} /> <AiOutlineUser  onClick={() => navigate(`/clientprofile/${client._id}`)}/>
        </div>
        <div>Cliente - {client?.name} {client?.qualification?.[0]?.qualification || 'Sin calificación'}</div>
      </div>
      <div>
        <form className="addClient-form" onSubmit={onSubmit}>
          <h2>Agregar los datos del cliente</h2>
          <input
            type="text"
            name="fansteam"
            value={fansteam}
            placeholder="Fans Club"
            onChange={onChange}
          />
          <input
            type="text"
            name="sport"
            value={sport}
            placeholder="Deporte que practica"
            onChange={onChange}
          />
          <input
            type="text"
            name="sex"
            value={sex}
            placeholder="Genero"
            onChange={onChange}
          />

          <input
            type="text"
            name="religion"
            value={religion}
            placeholder="Religión"
            onChange={onChange}
          />
          <input
            type="text"
            name="titleprofession"
            value={titleprofession}
            placeholder="Profesion"
            onChange={onChange}
          />
          <input
            type="text"
            name="celebrationDay"
            value={celebrationDay}
            placeholder="MM/DD - Dia de celebración"
            onChange={onChange}
          />

          <Button type="primary" block htmlType="submit" className="ant-btn">
            Agregar Informacion del Cliente
          </Button>
        </form>
      </div>
    </div>
  );
};

export default AddPersonalData;
