import { configureStore } from '@reduxjs/toolkit';
import auth from '../features/auth/authSlice'
import clients from '../features/client/clientSlice'
import data from '../features/data/dataSlice'


export const store = configureStore({
  reducer: {
    auth,
    clients,
    data,
  },
});
