import { Button, Checkbox, Modal } from 'antd';
import React from 'react'

const Publication = ({ modalVisibleP, onClose }) => {
  
  const tasksPublication = [
    "Fotos/Home Staging-Video Agencia de Marketing - Video-COTI",
    "Publicación en portales + Descripción segmentada + Cartel",
    "Tarjetón de agradecimiento (regalo)",
    "Semana 1 / Base de datos y MLS / 1° Informe RE/MAX + Flyer + Link de portales + Gastos y costos a la hora de vender y comprar - Búsqueda de reubicación (solo si quiere comprar otro inmueble o se quiera reubicar)",
    "Semana 2 / Informe + Campaña Facebook",
    "Semana 3 / Informe + Publicidad Instagram / Mostrar nueva competencia",
    "Semana 4 / Informe mensual + Destacado Zonaprop",
    "Semana 5 / Nueva campaña de Facebook",
    "Semana 6 / Visita en grupo + Reporte RE/MAX",
    "Semana 7 / Nuevas fotos / Nuevo video / Informe RE/MAX + Replanteo precio",
    "Semana 8 / Nueva competencia / Precio / Reporte de los 2 meses",
    "Semana 9 / Nuevo precio / Nueva campaña de Instagram",
    "Campaña Argenprop + Reporte de competencias",
    "Base de datos + MLS + Reporte + Farming",
    "Hacer fotos en visitas o acciones de marketing para el video final",
  ];
  
      
    
      return (
        <Modal
          title="Checklist de Publicación"
          open={modalVisibleP}
          onCancel={onClose}
          footer={[
            <Button key="back" onClick={onClose}>
              Cerrar
            </Button>,
          ]}
        >
          
            {tasksPublication.map((task, index) => (
              <p key={index}>
                <Checkbox>{task}</Checkbox>
              </p>
            ))}
        </Modal>
      );
    };
    export default Publication;
    