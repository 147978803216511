// dataSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dataService from "./dataService";

const initialState = {
  personalData: {},
  activities: [],
  followUpActions: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: "",
};

export const createPersData = createAsyncThunk(
  "data/createPersData",
  async (clientPersData) => {
    try {
      return await dataService.createPersData(clientPersData);
    } catch (error) {
      console.error(error);
    }
  }
);
export const createFollowUpActions = createAsyncThunk(
  "followupactions/createFollowUpActions",
  async (clientfollowA) => {
    try {
      return await dataService.createFollowUpActions(clientfollowA);
    } catch (error) {
      console.error(error);
    }
  }
);
export const createFamilyData = createAsyncThunk(
  "familyData/createFamilyData",
  async (familyData) => {
    try {
      return await dataService.createFamilyData(familyData);
    } catch (error) {
      console.error(error);
    }
  }
);
export const createQualification = createAsyncThunk(
  "qualifications/createQualification",
  async (clientQualiData) => {
    try {
      return await dataService.createQualification(clientQualiData);
    } catch (error) {
      console.error(error);
    }
  }
);

export const createStatus = createAsyncThunk(
  "status/createStatus",
  async (status) => {
    try {
      return await dataService.createStatus(status);
    } catch (error) {
      console.error(error);
    }
  }
);

export const UpdateStatus = createAsyncThunk(
  "status/UpdateStatus",
  async (data, thunkAPI) => {
    try {
      return await dataService.UpdateStatus(data);
    } catch (error) {
      const message = error.response.data.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createNewActivity = createAsyncThunk(
  "newActivity/createNewActivity",
  async (data) => {
    try {
      return await dataService.createNewActivity(data);
    } catch (error) {
      console.error(error);
    }
  }
);

export const getAllActivities = createAsyncThunk(
  "newActivity/getAllActivities",
  async () => {
    try {
      const activities = await dataService.getAllActivities();
      return activities;  // Devuelve las actividades
    } catch (error) {
      console.error(error);
      throw error;  // Lanza el error para que pueda ser manejado en el caso 'rejected'
    }
  }
);

export const updateActivity = createAsyncThunk(
  "newActivity/updateActivity",
  async (data) => {
    try {
      return await dataService.updateActivity(data);
    } catch (error) {
      console.error(error);
    }
  }
);

export const updateQualification = createAsyncThunk(
  "qualifications/updateQualification",
  async (data) => {
    try {
      return await dataService.updateQualification(data);
    } catch (error) {
      console.error(error);
    }
  }
);

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    reset: (state) => {
      state.isSuccess = false;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPersData.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(createFamilyData.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(createFollowUpActions.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(createQualification.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(createStatus.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(UpdateStatus.fulfilled, (state, action) => {
        state.data = action.payload.data;
      })
      .addCase(createNewActivity.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(getAllActivities.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.activities = action.payload;  // Asigna las actividades al estado
      })
      .addCase(getAllActivities.rejected, (state, action) => {
        state.isError = true;
        state.message = action.error.message;  // Puedes personalizar esto según tus necesidades
      })
      .addCase(updateActivity.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(updateQualification.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.data = action.payload;
      });
  },
});

export const { reset, isLoading } = dataSlice.actions;
export default dataSlice.reducer;
